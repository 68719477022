<template>
  <main>
    <div v-if="userAccount && offering">
      <div>
        <div v-if="investments && investments.length > 0">
          <h6>{{ offering.name }} Draft Investments</h6>
          <p>Select an investment to edit:</p>
          <div class="row border" v-for="investment in investments" :key="investment.id">
            <div class="name col-md-2 align-self-center">
              {{ investment.investmentName }}
            </div>
            <div class="name col-md-2 align-self-center">
              {{ investment.investmentType }}
            </div>
            <div class="name col-md-1 align-self-center">
              ${{ investment.totalInvestment }}
            </div>
            <div class="name col-md-3 align-self-center">
              {{ investment.createdAt.toDate().toLocaleString() }}
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-3 align-self-center">
              <div class="row row-cols-md-3">
                <div v-if="investment.zsDocumentRequestId != null &&
                  investment.zsDocumentRequestId !== ''
                  " class="col align-self-center">
                  <router-link :to="{
                    name: 'InvestmentReview',
                    params: {
                      id: investment.offeringId,
                      draftId: investment.id
                    }
                  }" class="btn btn-primary">Invest
                  </router-link>
                </div>
                <div class="col align-self-center">
                  <router-link :to="{
                    name: 'InvestorDetails',
                    params: {
                      id: investment.offeringId,
                      draftId: investment.id
                    }
                  }" class="btn btn-primary">Edit
                  </router-link>
                </div>
                <div class="col align-self-center">
                  <span v-if="investment.status == 'draft'" class="btn" data-bs-toggle="modal"
                    data-bs-target="#confirmDeleteModal" @click="cancelInvestmentId = investment.id">
                    <h3 class="text-danger pt-3">&times;</h3>
                  </span>
                  <button v-else class="btn" disabled>
                    <h4 class="text-secondary pt-3">&times;</h4>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <p>or start a new investment:</p>
        </div>
      </div>
      <form @submit.prevent="handleSubmit">
        <h6>New Investment Details</h6>
        <div class="form-outline mb-4 container-fluid">
          <label>Investment Type</label>
          <div class="pt-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="investmentType" id="individualInvestment"
                v-model="investmentType" value="individual" />
              <label class="form-check-label" for="individualInvestment">I will be investing for myself</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="investmentType" id="jointInvestment"
                v-model="investmentType" value="joint" />
              <label class="form-check-label" for="joinInvestment">I will be investing jointly</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="investmentType" id="entityInvestment"
                v-model="investmentType" value="entity" />
              <label class="form-check-label" for="joinInvestment">I will be investing for an entity</label>
            </div>
          </div>
        </div>
        <div v-if="investmentType == 'joint' || investmentType == 'individual'">
          <h6 v-if="investmentType == 'joint'">Primary Investor</h6>
          <div v-if="!userAccount.streetAddress1" class=" form-outline mb-4 container-fluid">
            Save your account contact information
            <router-link :to="{
              name: 'Account',
              params: { id: offering.id }
            }" class="router-link">
              here.
            </router-link>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col">
                <label>First Name</label>
                <input type="text" v-model="userAccount.firstName" class="form-control" required />
              </div>
              <div class="col-md-3">
                <label>Middle Name</label>
                <input type="text" v-model="userAccount.middleName" class="form-control" />
              </div>
              <div class="col">
                <label>Last Name</label>
                <input type="text" v-model="userAccount.lastName" class="form-control" required />
              </div>
              <div class="col-2">
                <label>Suffix</label>
                <input type="text" v-model="userAccount.suffix" class="form-control" />
              </div>
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md-4">
                <label>Date of Birth</label>
                <input type="date" v-model="userAccount.dateOfBirth" class="form-control" required />
              </div>
              <div class="col-md-4">
                <label>Phone Number</label>
                <input type="text" v-model="userAccount.phone" class="form-control" required />
              </div>
              <div v-if="false" class="col-md-4">
                <label>Alternate Phone</label>
                <input type="text" v-model="userAccount.phone2" class="form-control" />
              </div>
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="primaryCitizen" v-model="isPrimaryCitizen" />
                  <label class="form-check-label">
                    I am a citizen or resident of the United States with an SSN
                  </label>
                </div>
                <div v-if="isPrimaryCitizen">
                  <label>Social Security Number</label>
                  <input type="text" placeholder="###-##-####" v-model="primarySSN" class="form-control" required />
                </div>
              </div>
              <div class="col">
                <div v-if="!isPrimaryCitizen" class="form-check">
                  <input class="form-check-input" type="checkbox" id="primaryCitizen" v-model="isPrimaryITIN" />
                  <label class="form-check-label">
                    I have an ITIN
                  </label>
                </div>
                <div v-if="!isPrimaryCitizen && isPrimaryITIN">
                  <label>ITIN</label>
                  <input type="text" placeholder="###-##-####" v-model="primarySSN" class="form-control" required />
                </div>
                <div v-if="!isPrimaryCitizen && !isPrimaryITIN">
                  <p>
                    You can continue investing without an ITIN, but please begin
                    the application process with the IRS as soon as possible.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <AddressFieldGroup v-model:addressLine1="userAccount.streetAddress1"
            v-model:addressLine2="userAccount.streetAddress2" v-model:city="userAccount.city"
            v-model:state="userAccount.region" v-model:postalCode="userAccount.postalCode"
            v-model:country="userAccount.country" />

          <div v-if="investmentType == 'joint'">
            <h6>Secondary Investor</h6>
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <div class="col">
                  <label>First Name</label>
                  <input type="text" v-model="secondaryFirstName" class="form-control" required />
                </div>
                <div class="col-md-3">
                  <label>Middle Name</label>
                  <input type="text" v-model="secondaryMiddleName" class="form-control" />
                </div>
                <div class="col">
                  <label>Last Name</label>
                  <input type="text" v-model="secondaryLastName" class="form-control" required />
                </div>
                <div class="col-2">
                  <label>Suffix</label>
                  <input type="text" v-model="secondarySuffix" class="form-control" />
                </div>
              </div>
            </div>
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <div class="col-md-4">
                  <label>Date of Birth</label>
                  <input type="date" v-model="secondaryDateOfBirth" class="form-control" required />
                </div>
                <div class="col-md-4">
                  <label>Phone Number</label>
                  <input type="text" v-model="secondaryPhoneNumber" class="form-control" required />
                </div>
                <div class="col-md-4">
                  <label>Email Address</label>
                  <input type="text" v-model="secondaryEmail" class="form-control"
                    @blur="errorSecondaryEmail = validateEmail(secondaryEmail)" required />
                  <div class="error">{{ errorSecondaryEmail }}</div>
                </div>
              </div>
            </div>
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="secondaryCitizen" v-model="isSecondaryCitizen" />
                    <label class="form-check-label">
                      2nd investor is a citizen or resident of the United States
                      with an SSN
                    </label>
                  </div>
                  <div v-if="isSecondaryCitizen">
                    <label>Social Security Number</label>
                    <input type="text" placeholder="###-##-####" v-model="secondarySSN" class="form-control" required />
                  </div>
                </div>
                <div class="col">
                  <div v-if="!isSecondaryCitizen" class="form-check">
                    <input class="form-check-input" type="checkbox" id="primaryCitizen" v-model="isSecondaryITIN" />
                    <label class="form-check-label">
                      2nd investor has an ITIN
                    </label>
                  </div>
                  <div v-if="!isSecondaryCitizen && isSecondaryITIN">
                    <label>ITIN</label>
                    <input type="text" placeholder="###-##-####" v-model="secondarySSN" class="form-control" required />
                  </div>
                  <div v-if="!isSecondaryCitizen && !isSecondaryITIN">
                    <p>
                      You can continue investing without an ITIN, but please
                      begin the application process with the IRS as soon as
                      possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <AddressFieldGroup v-model:addressLine1="secondaryAddressLine1" v-model:addressLine2="secondaryAddressLine2"
              v-model:city="secondaryCity" v-model:state="secondaryState" v-model:postalCode="secondaryPostalCode"
              v-model:country="secondaryCountry" />
          </div>
        </div>
        <div v-if="investmentType == 'entity'">
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md">
                <label>Entity Name</label>
                <input type="text" v-model="entityName" class="form-control" required />
              </div>
              <div class="col-md">
                <label>Entity Start Date</label>
                <input type="date" v-model="entityStartDate" class="form-control" required />
              </div>
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md">
                <label>Entity Type</label>
                <EntityTypeSelector :selectedType="entityType" @select-type="entityType = $event">
                </EntityTypeSelector>
              </div>
              <div class="col-md">
                <label>Entity Industry</label>
                <EntityIndustrySelector :selectedIndustry="entityIndustry" @select-industry="entityIndustry = $event">
                </EntityIndustrySelector>
              </div>
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="finCenRegulated" v-model="isFinCenRegulated" />
                  <label class="form-check-label">
                    This entity is FinCen Regulated
                  </label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="usEntity" v-model="isUSEntity" />
                  <label class="form-check-label">
                    This is a US Entity with a TIN/EIN
                  </label>
                </div>
                <div v-if="isUSEntity">
                  <label>TIN/EIN</label>
                  <input type="text" placeholder="EIN/TIN" v-model="entityTaxId" class="form-control" required />
                </div>
              </div>
              <div v-if="!isUSEntity" class="col-md">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="EntityFtin" v-model="isEntityFtin" />
                  <label class="form-check-label">
                    This entity has a FTIN
                  </label>
                </div>
                <div v-if="isEntityFtin">
                  <label>Entity FTIN</label>
                  <input type="text" placeholder="FTIN" v-model="entityTaxId" class="form-control" required />
                </div>
              </div>
            </div>
          </div>

          <h6>Company Address</h6>
          <AddressFieldGroup v-model:addressLine1="entityStreetAddress1" v-model:addressLine2="entityStreetAddress2"
            v-model:city="entityCity" v-model:state="entityState" v-model:postalCode="entityPostalCode"
            v-model:country="entityCountry" />
          <h6>Purchaser Representative / Owner Signatory</h6>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-lg">
                <label>Representative Name</label>
                <input type="text" v-model="entityRepresentativeName" class="form-control" required />
              </div>
              <div class="col-lg">
                <label>Representative Title</label>
                <input type="text" v-model="entityRepresentativeTitle" class="form-control" required />
              </div>
              <div class="col-lg">
                <label>Representative Phone</label>
                <input type="text" v-model="entityRepresentativePhoneNumber" class="form-control" required />
              </div>
              <div class="col-lg">
                <label>Representative Email</label>
                <input type="text" v-model="entityRepresentativeEmail" class="form-control"
                  @blur="errorRepresentativeEmail = validateEmail(entityRepresentativeEmail)" required />
                <div class="error">{{ errorRepresentativeEmail }}</div>
              </div>
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <AddressFieldGroup v-model:addressLine1="entityRepresentativeStreetAddress1"
                v-model:addressLine2="entityRepresentativeStreetAddress2" v-model:city="entityRepresentativeCity"
                v-model:state="entityRepresentativeState" v-model:postalCode="entityRepresentativePostalCode"
                v-model:country="entityRepresentativeCountry" />
            </div>
          </div>
          <h6>Owners (> 10% ownership)</h6>
          <div class="form-outline mb-2 container-fluid">
            <div class="row">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="EntityOwnersAccredited"
                  v-model="isEntityOwnersAccredited" />
                <label class="form-check-label">
                  All owners are accredited
                </label>
              </div>
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div v-for="(owner, index) in owners" :key="index">
              <div class="row">
                <div class="col-lg">
                  <label>{{ index + 1 }}. Owner Name</label>
                  <input type="text" v-model="owner.entityOwnerName" class="form-control" required />
                </div>
                <div class="col-lg">
                  <label>Owner Date of Birth</label>
                  <input type="date" v-model="owner.entityOwnerDateOfBirth" class="form-control" required />
                </div>
                <div class="col-lg">
                  <label>Owner SSN/ITIN</label>
                  <input type="text" v-model="owner.entityOwnerSSN" class="form-control" required />
                </div>
                <div class="col-lg">
                  <label>Owner Email Address</label>
                  <input type="text" v-model="owner.entityOwnerEmail" class="form-control"
                    @blur="owner.entityOwnerEmailError = validateEmail(owner.entityOwnerEmail)" required />
                  <div class="error">{{ owner.entityOwnerEmailError }}</div>
                </div>
                <div class="col-1 d-flex align-items-center">
                  <span v-if="owners.length > 1" class="btn" @click="handleDeleteOwner(index)">
                    <h3 class="text-danger pt-3">&times;</h3>
                  </span>
                  <button v-else class="btn" disabled>
                    <h3 class="text-secondary pt-3">&times;</h3>
                  </button>
                </div>
              </div>
              <div class="row">
                <AddressFieldGroup v-model:addressLine1="owner.entityOwnerStreetAddress1"
                  v-model:addressLine2="owner.entityOwnerStreetAddress2" v-model:city="owner.entityOwnerCity"
                  v-model:state="owner.entityOwnerState" v-model:postalCode="owner.entityOwnerPostalCode"
                  v-model:country="owner.entityOwnerCountry" />
              </div>
            </div>
            <span v-if="owners.length < 10" class="btn btn-primary" @click="handleAddOwner()">
              Add Another Owner
            </span>
            <button v-else class="btn btn-primary" disabled>
              Add Another Owner
            </button>
          </div>
          <div class="form-outline container-fluid">
            <div class="row">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="isAuthorized" @click="errorOffering = ''"
                  v-model="isEntityRepresentativeAuthorized" />
                <label class="form-check-label">
                  I am authorized to make investments for this entity.
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-outline mb-4 container-fluid">
          <div v-if="errorOffering" class="text-danger pt-3">
            {{ errorOffering }}
          </div>
          <div v-if="errorSecondaryEmail && investmentType == 'joint'" class="error">Invalid Secondary Email</div>
          <div v-if="errorRepresentativeEmail && investmentType == 'entity'" class="error">Invalid Representative Email
          </div>

        </div>
        <div class="row text-center">
          <div class="col">
            <router-link :to="{ name: 'Offering', params: { id: offering.id } }" class="btn btn-secondary">
              Cancel
            </router-link>
          </div>
          <div class="col">
            <div
              v-if="(errorSecondaryEmail && investmentType == 'joint') || (errorRepresentativeEmail && investmentType == 'entity')">
              <button class="btn btn-primary" disabled>
                Start New Investment<i class="bi bi-arrow-right"></i>
              </button>
            </div>
            <div v-else>
              <button class="btn btn-primary">
                Start New Investment
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <ModalDelete title="Delete Investment?" @confirm-delete="handleDeleteDraft">
      Are you sure you want to cancel and permanently delete this investment?
      This action cannot be undone.
    </ModalDelete>
  </main>
  <Footer v-if="userAccount" />
</template>

<script>
import { timestamp } from "@/firebase/config";
import ModalDelete from "@/components/modals/ModalDelete.vue";
import getUser from "@/composables/getUser";
import Footer from "@/components/navigation/Footer.vue";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import getCollection from "@/composables/getCollection";
import useCollection from "@/composables/useCollection";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import AddressFieldGroup from "@/components/AddressFieldGroup.vue";
import EntityTypeSelector from "@/components/EntityTypeSelector.vue";
import EntityIndustrySelector from "@/components/EntityIndustrySelector.vue";

export default {
  props: ["id"],
  components: {
    Footer,
    ModalDelete,
    AddressFieldGroup,
    EntityTypeSelector,
    EntityIndustrySelector
  },
  setup(props) {
    const validEmail = ref(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const owners = ref([
      {
        entityOwnerName: "",
        entityOwnerDateOfBirth: "",
        entityOwnerSSN: "",
        entityOwnerEmail: "",
        entityOwnerStreetAddress1: "",
        entityOwnerStreetAddress2: "",
        entityOwnerCity: "",
        entityOwnerState: "",
        entityOwnerPostalCode: "",
        entityOwnerCountry: "",
        entityOwnerEmailError: ""
      }
    ]);
    const errorSecondaryEmail = ref(null);
    const errorRepresentativeEmail = ref(null);
    const isPrimaryCitizen = ref(true);
    const primarySSN = ref("");
    const isPrimaryITIN = ref(false);
    const entityRepresentativeName = ref("");
    const entityRepresentativeTitle = ref("");
    const isEntityRepresentativeAuthorized = ref(false);
    const isEntityOwnersAccredited = ref(false);
    const entityRepresentativePhoneNumber = ref("");
    const entityRepresentativeCountryCode = ref("1");
    const entityRepresentativePhoneNumber2 = null;
    const entityRepresentativeCountryCode2 = null;
    const entityRepresentativeEmail = ref("");
    const entityRepresentativeStreetAddress1 = ref("");
    const entityRepresentativeStreetAddress2 = ref("");
    const entityRepresentativeCity = ref("");
    const entityRepresentativeState = ref("");
    const entityRepresentativePostalCode = ref("");
    const entityRepresentativeCountry = ref("");
    const entityStreetAddress1 = ref("");
    const entityStreetAddress2 = ref("");
    const entityCity = ref("");
    const entityState = ref("");
    const entityPostalCode = ref("");
    const entityCountry = ref("");
    const entityType = ref("");
    const entityName = ref("");
    const entityTaxId = ref("");
    const entityStartDate = ref("");
    const entityIndustry = ref("");
    const isFinCenRegulated = ref(false);
    const isUSEntity = ref(true);
    const isEntityFtin = ref(false);
    const secondaryFirstName = ref("");
    const secondaryMiddleName = ref("");
    const secondaryLastName = ref("");
    const secondarySSN = ref("");
    const isSecondaryCitizen = ref(true);
    const isSecondaryITIN = ref(false);
    const secondarySuffix = ref("");
    const secondaryDateOfBirth = ref("");
    const secondaryPhoneNumber = ref("");
    const secondaryEmail = ref("");
    const secondaryAddressLine1 = ref("");
    const secondaryAddressLine2 = ref("");
    const secondaryCity = ref("");
    const secondaryState = ref("");
    const secondaryPostalCode = ref("");
    const secondaryCountry = ref("");
    const isPending = ref(false);
    const investmentType = ref("individual");
    const investmentName = ref("");
    const cancelInvestmentId = ref(null);
    const { user } = getUser();
    const router = useRouter();
    const { document: userAccount } = getDocument("users", user.value.uid);
    const { error: errorOffering, document: offering } = getDocument(
      "offerings",
      props.id
    );
    const { error: errorInvestments, documents: investments } = getCollection(
      "investmentDrafts",
      [
        ["investorId", "==", user.value.uid],
        ["offeringId", "==", props.id],
        ["status", "==", "draft"]
      ],
      ["createdAt", "desc"]
    );
    const { error: errorAddDoc, addDoc } = useCollection("investmentDrafts");

    const handleAddOwner = async () => {
      owners.value.push({
        entityOwnerName: "",
        entityOwnerDateOfBirth: "",
        entityOwnerSSN: "",
        entityOwnerEmail: "",
        entityOwnerStreetAddress1: "",
        entityOwnerStreetAddress2: "",
        entityOwnerCity: "",
        entityOwnerState: "",
        entityOwnerPostalCode: "",
        entityOwnerCountry: ""
      });
    };

    const validateEmail = (emailAddress) => {
      let error = null;
      if (emailAddress.length > 0 && !validEmail.value.test(emailAddress)) {
        error = "Invalid Email Address.";
      }
      return error;
    };

    const handleDeleteOwner = async index => {
      if (owners.value.length > 1) {
        owners.value.splice(index, 1);
      }
    };

    const handleDeleteDraft = async () => {
      console.log(`Delete Draft Investment ${cancelInvestmentId.value}`);
      const { error: docError, deleteDoc } = useDocument(
        "investmentDrafts",
        cancelInvestmentId.value
      );
      await deleteDoc();
      if (docError.value) {
        errorInvestments.value = docError.value;
      }
    };

    const handleSubmit = async () => {
      try {
        errorOffering.value = "";
        if (
          investmentType.value == "entity" &&
          !isEntityRepresentativeAuthorized.value == true
        ) {
          errorOffering.value =
            "You must verify that you are authorized to make investments for this entity by checking the box.";
        } else {
          isPending.value = true;
          errorOffering.value = null;
          switch (investmentType.value) {
            case "individual":
              investmentName.value = `${userAccount.value.firstName} ${userAccount.value.lastName}`;
              break;
            case "joint":
              investmentName.value = `${userAccount.value.firstName} & ${secondaryFirstName.value}`;
              break;
            case "entity":
              investmentName.value = entityName.value;
              break;
          }
          const refInvestment = await addDoc({
            paymentAccountHolderName: "",
            paymentAccountType: "",
            paymentAccountRoutingNumber: "",
            paymentAccountNumber: "",
            paymentAccountNumberConfirmed: "",
            entityRepresentativeName: entityRepresentativeName.value,
            entityRepresentativeTitle: entityRepresentativeTitle.value,
            isEntityRepresentativeAuthorized:
              isEntityRepresentativeAuthorized.value,
            isEntityOwnersAccredited: isEntityOwnersAccredited.value,
            entityRepresentativePhoneNumber:
              entityRepresentativePhoneNumber.value,
            entityRepresentativeCountryCode:
              entityRepresentativeCountryCode.value,
            entityRepresentativePhoneNumber2: entityRepresentativePhoneNumber2,
            entityRepresentativeCountryCode2: entityRepresentativeCountryCode2,
            entityRepresentativeEmail: entityRepresentativeEmail.value,
            entityRepresentativeStreetAddress1:
              entityRepresentativeStreetAddress1.value,
            entityRepresentativeStreetAddress2:
              entityRepresentativeStreetAddress2.value,
            entityRepresentativeCity: entityRepresentativeCity.value,
            entityRepresentativeState: entityRepresentativeState.value,
            entityRepresentativePostalCode:
              entityRepresentativePostalCode.value,
            entityRepresentativeCountry: entityRepresentativeCountry.value,
            entityStreetAddress1: entityStreetAddress1.value,
            entityStreetAddress2: entityStreetAddress2.value,
            entityCity: entityCity.value,
            entityState: entityState.value,
            entityPostalCode: entityPostalCode.value,
            entityCountry: entityCountry.value,
            investmentName: investmentName.value,
            investmentType: investmentType.value,
            isLegalInvestment: false,
            investorId: user.value.uid,
            c2mOfferingId: offering.value.c2mOfferingId,
            issuerId: offering.value.issuerId,
            issuerName: offering.value.issuerName,
            offeringId: offering.value.id,
            offeringName: offering.value.name,
            offeringCloses: offering.value.offeringCloses,
            offeringType: offering.value.offeringType,
            offeringTypeDescription: offering.value.offeringTypeDescription,
            contactName: offering.value.contactName,
            contactEmail: offering.value.contactEmail,
            contactPhone: offering.value.contactPhone,
            entityType: entityType.value,
            entityName: entityName.value,
            entityTaxId: entityTaxId.value,
            entityStartDate: entityStartDate.value,
            entityIndustry: entityIndustry.value,
            isFinCenRegulated: isFinCenRegulated.value,
            isUSEntity: isUSEntity.value,
            isEntityFtin: isEntityFtin.value,
            owners: owners.value,
            primaryFullName: `${userAccount.value.firstName} ${userAccount.value.middleName ? userAccount.value.middleName : ""
              } ${userAccount.value.lastName}`,
            primaryFirstName: userAccount.value.firstName
              ? userAccount.value.firstName
              : "",
            primaryMiddleName: userAccount.value.middleName
              ? userAccount.value.middleName
              : "",
            primaryLastName: userAccount.value.lastName
              ? userAccount.value.lastName
              : "",
            primarySuffix: userAccount.value.suffix
              ? userAccount.value.suffix
              : "",
            primaryDateOfBirth: userAccount.value.dateOfBirth
              ? userAccount.value.dateOfBirth
              : "",
            primaryPhoneNumber: userAccount.value.phone
              ? userAccount.value.phone
              : "",
            primaryCountryCode: "1",
            primaryPhoneNumber2: null, //userAccount.value.phone2,
            primaryCountryCode2: "1",
            primarySSN: primarySSN.value,
            isPrimaryCitizen: isPrimaryCitizen.value,
            isPrimaryITIN: isPrimaryITIN.value,
            primaryEmail: userAccount.value.email,
            primaryAddressLine1: userAccount.value.streetAddress1
              ? userAccount.value.streetAddress1
              : "",
            primaryAddressLine2: userAccount.value.streetAddress2
              ? userAccount.value.streetAddress2
              : "",
            primaryCity: userAccount.value.city ? userAccount.value.city : "",
            primaryState: userAccount.value.region
              ? userAccount.value.region
              : "",
            primaryPostalCode: userAccount.value.postalCode
              ? userAccount.value.postalCode
              : "",
            primaryCountry: userAccount.value.country
              ? userAccount.value.country
              : "",
            primaryAnnualIncome: 0,
            primaryNetWorth: 0,
            primaryAccreditedInvestor: "no",
            primaryOtherInvestments: "no",
            primaryOtherInvestmentTotal: 0,
            primaryInvestmentLimit: 0,
            pricePerShare: offering.value.pricePerShare,
            totalShares: 0,
            totalInvestment: 0,
            totalAmount: 0,
            totalFee: 0,
            secondaryFullName: `${secondaryFirstName.value} ${secondaryMiddleName.value} ${secondaryLastName.value}`,
            secondaryFirstName: secondaryFirstName.value,
            secondaryMiddleName: secondaryMiddleName.value,
            secondaryLastName: secondaryLastName.value,
            secondarySuffix: secondarySuffix.value,
            secondarySSN: secondarySSN.value,
            isSecondaryCitizen: isSecondaryCitizen.value,
            isSecondaryITIN: isSecondaryITIN.value,
            secondaryDateOfBirth: secondaryDateOfBirth.value,
            secondaryPhoneNumber: secondaryPhoneNumber.value,
            secondaryEmail: secondaryEmail.value,
            secondaryAddressLine1: secondaryAddressLine1.value,
            secondaryAddressLine2: secondaryAddressLine2.value,
            secondaryCity: secondaryCity.value,
            secondaryState: secondaryState.value,
            secondaryPostalCode: secondaryPostalCode.value,
            secondaryCountry: secondaryCountry.value,
            zsIsDocumentSigned: false,
            zsSignatureTime: "",
            interestRate: "",
            maturityDate: "",
            maturityYears: "",
            rewards: "",
            paymentMethod: "ACH",
            status: "draft",
            zsDocumentRequestId: "",
            zsDocumentActionId: "",
            createdAt: timestamp()
          });
          if (errorAddDoc.value) {
            console.log(`Error: ${errorAddDoc.value}`);
            errorOffering.value = errorAddDoc.value;
          } else {
            console.log(`Ref ID: ${refInvestment.id}`);
            router.push({
              name: "InvestmentDetails",
              params: { id: offering.value.id, draftId: refInvestment.id }
            });
          }
        }
      } catch (err) {
        errorOffering.value = err.message;
      }
    };

    return {
      primarySSN,
      isPrimaryCitizen,
      isPrimaryITIN,
      owners,
      entityRepresentativeName,
      entityRepresentativeTitle,
      isEntityRepresentativeAuthorized,
      isEntityOwnersAccredited,
      entityRepresentativePhoneNumber,
      entityRepresentativeEmail,
      entityRepresentativeStreetAddress1,
      entityRepresentativeStreetAddress2,
      entityRepresentativeCity,
      entityRepresentativeState,
      entityRepresentativePostalCode,
      entityRepresentativeCountry,
      entityStreetAddress1,
      entityStreetAddress2,
      entityCity,
      entityState,
      entityPostalCode,
      entityCountry,
      entityType,
      entityName,
      entityTaxId,
      entityStartDate,
      entityIndustry,
      isFinCenRegulated,
      isUSEntity,
      isEntityFtin,
      secondaryFirstName,
      secondaryMiddleName,
      secondaryLastName,
      secondarySuffix,
      secondarySSN,
      isSecondaryCitizen,
      isSecondaryITIN,
      secondaryDateOfBirth,
      secondaryPhoneNumber,
      secondaryEmail,
      secondaryAddressLine1,
      secondaryAddressLine2,
      secondaryCity,
      secondaryState,
      secondaryPostalCode,
      secondaryCountry,
      userAccount,
      errorOffering,
      offering,
      investments,
      errorInvestments,
      cancelInvestmentId,
      investmentType,
      investmentName,
      errorSecondaryEmail,
      errorRepresentativeEmail,
      handleSubmit,
      handleDeleteDraft,
      handleDeleteOwner,
      handleAddOwner,
      validateEmail
    };
  }
};
</script>

<style></style>
<template>
  <div id="v-model-select">
    <select
      v-model="entityIndustry"
      @change="handleChange"
      class="form-control"
      required
    >
      <option disabled value="">Please select an Industry</option>
      <option value="Not Known">Not Known</option>
      <option value="Aerospace/Defense">Aerospace/Defense</option>
      <option value="Airport">Airport</option>
      <option value="Arts & Entertainment">Arts & Entertainment</option>
      <option value="Automobiles and Parts">Automobiles and Parts</option>
      <option value="Automotive">Automotive</option>
      <option value="Bank & Financial Services"
        >Bank & Financial Services</option
      >
      <option value="Bank/Financial Institution"
        >Bank/Financial Institution</option
      >
      <option value="Bar">Bar</option>
      <option value="Biotechnology">Biotechnology</option>
      <option value="Book Store">Book Store</option>
      <option value="Business Services">Business Services</option>
      <option value="Cause">Cause</option>
      <option value="Chemicals">Chemicals</option>
      <option value="Club">Club</option>
      <option value="Community Organization">Community Organization</option>
      <option value="Community/Government">Community/Government</option>
      <option value="Company">Company</option>
      <option value="Computers/Technology">Computers/Technology</option>
      <option value="Concert Venue">Concert Venue</option>
      <option value="Consulting/Business Services"
        >Consulting/Business Services</option
      >
      <option value="Doctor">Doctor</option>
      <option value="Education">Education</option>
      <option value="Elementary School">Elementary School</option>
      <option value="Energy/Utility">Energy/Utility</option>
      <option value="Engineering/Construction">Engineering/Construction</option>
      <option value="Event Planning/Event Services"
        >Event Planning/Event Services</option
      >
      <option value="Farming/Agriculture">Farming/Agriculture</option>
      <option value="Food/Beverages">Food/Beverages</option>
      <option value="Food/Grocery">Food/Grocery</option>
      <option value="Government Organization">Government Organization</option>
      <option value="Health/Beauty">Health/Beauty</option>
      <option value="Health/Medical/Pharmaceuticals"
        >Health/Medical/Pharmaceuticals</option
      >
      <option value="Health/Medical/Pharmacy">Health/Medical/Pharmacy</option>
      <option value="Home Improvement">Home Improvement</option>
      <option value="Hospital/Clinic">Hospital/Clinic</option>
      <option value="Hotel">Hotel</option>
      <option value="Industrials">Industrials</option>
      <option value="Insurance Company">Insurance Company</option>
      <option value="Internet/Software">Internet/Software</option>
      <option value="Landmark">Landmark</option>
      <option value="Lawyer">Lawyer</option>
      <option value="Legal/Law">Legal/Law</option>
      <option value="Library">Library</option>
      <option value="Licensed Financial Representative"
        >Licensed Financial Representative</option
      >
      <option value="Local Business">Local Business</option>
      <option value="Media/News/Publishing">Media/News/Publishing</option>
      <option value="Middle School">Middle School</option>
      <option value="Mining/Materials">Mining/Materials</option>
      <option value="Movie Theater">Movie Theater</option>
      <option value="Museum/Art Gallery">Museum/Art Gallery</option>
      <option value="Non-Governmental Organization (NGO)"
        >Non-Governmental Organization (NGO)</option
      >
      <option value="Non-Profit Organization">Non-Profit Organization</option>
      <option value="Organization">Organization</option>
      <option value="Outdoor Gear/Sporting Goods"
        >Outdoor Gear/Sporting Goods</option
      >
      <option value="Pet Services">Pet Services</option>
      <option value="Political Organization">Political Organization</option>
      <option value="Political Party">Political Party</option>
      <option value="Preschool">Preschool</option>
      <option value="Professional Services">Professional Services</option>
      <option value="Public Places">Public Places</option>
      <option value="Real Estate">Real Estate</option>
      <option value="Religious Organization">Religious Organization</option>
      <option value="Restaurant/Cafe">Restaurant/Cafe</option>
      <option value="Retail and Consumer Merchandise"
        >Retail and Consumer Merchandise</option
      >
      <option value="School">School</option>
      <option value="Shopping/Retail">Shopping/Retail</option>
      <option value="Small Business">Small Business</option>
      <option value="Spas/Beauty/Personal Care"
        >Spas/Beauty/Personal Care</option
      >
      <option value="Sports Venue">Sports Venue</option>
      <option value="Sports/Recreation/Activities"
        >Sports/Recreation/Activities</option
      >
      <option value="Telecommunication">Telecommunication</option>
      <option value="Tours/Sightseeing">Tours/Sightseeing</option>
      <option value="Train Station">Train Station</option>
      <option value="Transport/Freight">Transport/Freight</option>
      <option value="Transportation">Transportation</option>
      <option value="Travel/Leisure">Travel/Leisure</option>
      <option value="University">University</option>
    </select>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  props: ["selectedIndustry"],
  emits: ["select-industry"],
  setup(props, context) {
    const entityIndustry = ref(props.selectedIndustry);

    const handleChange = () => {
      context.emit("select-industry", entityIndustry.value);
    };

    return { entityIndustry, handleChange };
  }
};
</script>
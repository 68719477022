<template>
  <div id="v-model-select">
    <select
      v-model="entityType"
      @change="handleChange"
      class="form-control"
      required
    >
      <option disabled value="">Please select an Entity Type</option>
      <option value="C Corporation">C Corporation</option>
      <option value="S Corporation">S Corporation</option>
      <option value="Limited Liability Corporation"
        >Limited Liability Corporation</option
      >
      <option value="Partnership">Partnership</option>
      <option value="Sole Proprietorship">Sole Proprietorship</option>
      <option value="Trust">Trust</option>
      <option value="Estate">Estate</option>
    </select>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  props: ["selectedType"],
  emits: ["select-type"],
  setup(props, context) {
    const entityType = ref(props.selectedType);
    const handleChange = () => {
      context.emit("select-type", entityType.value);
    };

    return { entityType, handleChange };
  }
};
</script>